<template>
  <div class="project-list-container">
    <div class="project-list-header">
      Search projects...
      <div class="project-list-input">
        <Input
          search
          enter-button
          size="large"
          type="text"
          v-model="inputKeyWords"
          @on-search="onSearch"
        />
      </div>
    </div>
    <div v-if="loading" class="project-list-loading">
      <Icon type="ios-loading" class="project-list-loading-icon" />
      Please wait for a little seconds..
    </div>
    <div v-else>
      <div v-if="projectCount" class="project-list-info">
        {{ projectCount }} Results
      </div>
      <div v-if="projectCount" class="project-list">
        <div
          v-for="project in projectList"
          :key="project.id"
          class="project-list-card"
        >
          <router-link :to="'/function?id=' + project.id">
            <div class="project-list-card-body">
              <div class="project-list-card-body-name">
                {{ project.name }}
                <span v-if="!project.name" class="default"
                  >Empty Project Name</span
                >
              </div>
              <div class="project-list-card-body-abstract">
                {{ project.abstractInfo }}
                <span v-if="!project.abstractInfo" class="default"
                  >The author didn't write an abstract to this project.</span
                >
              </div>
              <div class="project-list-card-body-lang">
                <span
                  v-if="project.languages"
                  class="project-list-card-body-lang-tag"
                  >{{ project.languages }}</span
                >
              </div>
              <div class="project-list-card-body-date">
                updated on {{ project.updateTime }}
              </div>
              <div class="project-list-card-body-rundemo">
                <Button type="text" @click="runDemo">
                  <span><Icon type="md-play" color="#e9ab01" />Run Demo</span>
                </Button>
              </div>
              <div class="project-list-card-body-money">
                <div class="project-list-card-body-money-profit">
                  <div class="project-list-card-body-money-num">
                    ${{ project.totalProfit }}
                  </div>
                  <div class="project-list-card-body-money-text">
                    Total Profit
                  </div>
                </div>
                <div class="project-list-card-body-money-executable">
                  <div class="project-list-card-body-money-num">
                    ${{ project.executableCodePrice }}
                  </div>
                  <div class="project-list-card-body-money-text">
                    Executable Code
                  </div>
                </div>
              </div>

              <div class="project-list-card-background">
                <div class="project-list-card-background-content">
                  {{ project.name || "???" }}
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div
        v-else-if="!projectCount && latestKeyWords.length"
        class="project-list-empty"
      >
        There are no results for '<span class="project-list-empty-strong">{{
          latestKeyWords
        }}</span
        >'<br />
        Check your spelling or try different keywords
      </div>
      <div v-else class="project-list-empty">There are no projects now</div>
      <div class="project-list-pagination">
        <Page
          v-if="projectCount"
          show-sizer
          :current="currentPage"
          :total="projectCount"
          :page-size="pageSize"
          :page-size-opts="[15, 30, 45]"
          @on-page-size-change="onPageSizeChange"
          @on-change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      projectCount: 0,
      inputKeyWords: "",
      latestKeyWords: "",
      projectList: [],
      currentPage: 1,
      pageSize: 15,
    };
  },
  methods: {
    onSearch() {
      if (this.inputKeyWords === this.latestKeyWords) return;
      this.$router.push(`/projectList?input=${this.inputKeyWords}`);
    },
    search(val) {
      this.projectList = [];
      this.$Loading.start();
      if (this.loading) return
      this.loading = true;
      if (!val.length) {
        this.$axios({
          headers: {
            "Content-Type": "application/json",
          },
          method: "get",
          url: `/project/findAll?number=${this.currentPage - 1}&size=${
            this.pageSize
          }`,
        })
          .then((res) => {
            let data = res.data.data.items;
            this.projectCount = data.totalElements;
            this.projectList = data.content;
            this.latestKeyWords = val;
            this.$Loading.finish();
            this.loading = false;
          })
          .catch((err) => {
            console.log(error);
            this.latestKeyWords = val;
            this.$Loading.finish();
            this.loading = false;
          });
        return;
      }
      this.$axios({
        headers: {
          "Content-Type": "application/text",
        },
        method: "post",
        url: `/project/findByQuery?number=${this.currentPage - 1}&size=${
          this.pageSize
        }`,
        data: val,
      })
        .then((res) => {
          let data = res.data.data.items;
          this.projectCount = data.totalElements;
          this.projectList = data.content;
          this.latestKeyWords = val;
          this.$Loading.finish();
          this.loading = false;
        })
        .catch((err) => {
          console.log(error);
          this.latestKeyWords = val;
          this.$Loading.finish();
          this.loading = false;
        });
    },
    onPageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.search(this.latestKeyWords);
    },
    onPageChange(page) {
      this.currentPage = page;
      this.search(this.latestKeyWords);
    },
    runDemo() {
      let Tself = this;
      let hasLogin = true;
      let val = Tself.$cookies.get("user_id");
      if (val === null) {
        hasLogin = false;
      }
      if (hasLogin) {
        //this.$Message.warning('The project has not yet uploaded a demo on the cloud platform');
        //this.$router.push('/furtherInfo?id='+this.projectId)
        window.open("http://47.106.171.65:6080/vnc.html?password=123456");
      } else {
        this.$Message.warning("please login first");
      }
    },
  },
  watch: {
    $route(newVal, oldVal) {
      this.currentPage = 1;
      this.pageSize = 15;
      this.search(this.$route.query.input || "");
    },
  },
  mounted() {
    this.search(this.$route.query.input || "");
  },
};
</script>

<style lang="less">
.project-list-container {
  background-color: #fff;
  padding: 0 10%;
  .project-list-header {
    font-size: 30px;
    font-weight: 700;
    margin: 40px auto;
    text-align: left;
    .project-list-input {
      margin-top: 30px;
    }
  }
  .project-list-loading {
    font-size: 24px;
    &-icon {
      animation: rotate 1s linear infinite;
    }
    @keyframes rotate {
      0% {
        -webkit-transform: rotate(0deg);
      }
      25% {
        -webkit-transform: rotate(90deg);
      }
      50% {
        -webkit-transform: rotate(180deg);
      }
      75% {
        -webkit-transform: rotate(270deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
  }
  .project-list-info {
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
  }
  .project-list {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -10px;
    &-card {
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      min-height: 350px;
      width: 100%;
      max-width: 20%;
      padding: 0 10px;
      margin-bottom: 20px;
      @media screen and (max-width: 1500px) {
        max-width: 25%;
      }
      @media screen and (max-width: 1200px) {
        max-width: 33.33%;
      }
      &-body {
        position: relative;
        overflow: hidden;
        border: 1px solid #dcdee2;
        border-radius: 15px;
        height: 100%;
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .default {
          color: #bbb;
        }
        &:hover {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }
        &-name {
          padding: 5px;
          font-size: 18px;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: rgb(216, 164, 23);
        }
        &-abstract {
          font-size: 14px;
          margin: 5px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        &-lang {
          font-size: 12px;
          color: #888;
          padding: 0 10px;
          line-height: 30px;
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &-tag {
            border-radius: 10px;
            padding: 5px;
            color: #e9ab01;
            background-color: #ffedbe;
          }
        }
        &-date {
          font-size: 12px;
          color: #888;
          padding: 0 5px;
          text-align: right;
        }
        &-rundemo {
          margin-top: auto;
          span {
            color: #e9ab01;
          }
        }
        &-money {
          margin: 0 5%;
          display: flex;
          flex-direction: row;
          border-top: 1px solid #dcdee2;
          justify-content: space-between;
          &-num {
            font-weight: 700;
          }
          &-text {
            font-size: 12px;
            color: #888;
          }
          &-profit {
            width: 50%;
          }
        }
      }
      &-background {
        width: 70%;
        z-index: 0;
        white-space: nowrap;
        position: absolute;
        left: 10%;
        bottom: 5%;
        color: rgba(233, 171, 1, 0.1);
        font-size: 140px;
        transform-origin: 0 -50px;
        transform: rotateZ(-20deg);
        &-content:hover {
          margin-left: -30px;
          color: rgba(233, 171, 1, 0.4);
          transition-duration: 0.3s;
        }
        &-content {
          transition-duration: 0.3s;
        }
      }
    }
  }
  .project-list-pagination {
    margin-bottom: 30px;
  }
  .project-list-empty {
    text-align: left;
    font-size: 18px;
    &-strong {
      font-weight: 700;
    }
  }
}
</style>
